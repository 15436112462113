'use client';
import * as React from 'react';
import { Button, format, useLocale, Text } from '@dreamplan/ui';
import clsx from 'clsx';
import { socialInfo } from '../../../constants/info';
import { PageLinkType } from '../../../types/commonTypes';
import ArrowDown from '../../common/ArrowDown';

const HeroOther = (
  props: Omit<PageLinkType, 'classname navigation'> & {
    color: string;
    imgSrc?: string;
    buttonType: string;
    buttonLabel?: string;
  },
) => {
  const locale = useLocale();
  const {
    contentSubHeader,
    contentDescription,
    className,
    imgSrc,
    color,
    buttonType,
    buttonDescription,
  } = props;

  const noImageStyle = `
  flex flex-col gap-y-20 md:gap-y-24 -mt-[88px] md:-mt-[99px] h-screen -mx-[32px] lg:-mx-[96px] md:h-[700px] 2xl:-mx-[128px] px-8 md:px-16 lg:px-48 2xl:px-96 justify-center items-center text-black bg-secondary`;

  return (
    <section
      className={
        imgSrc
          ? clsx(
              `-mx-[32px] flex h-screen flex-col gap-y-20 md:h-[700px] md:gap-y-24 lg:-mx-[96px] lg:gap-y-0`,
              `xs:pb-10 bg-secondary relative items-center justify-center px-8 pb-20 text-black md:px-16 2xl:-mx-[128px] 2xl:px-32`,
            )
          : noImageStyle
      }
    >
      <div
        className={
          imgSrc
            ? 'xs:gap-y-3 relative flex flex-col items-center gap-y-10 pt-20 md:grid md:grid-cols-2'
            : 'xs:gap-y-3 grid justify-items-center gap-y-10 pt-20'
        }
      >
        <Text
          variant="h1"
          tag="h1"
          className={`${
            imgSrc ? 'py-0 text-center md:self-end md:text-left lg:pr-10' : 'text-center'
          }`}
        >
          {contentSubHeader}
        </Text>
        {imgSrc ? (
          <div className="xs:h-60 xs:w-60 relative self-center md:row-span-2 md:h-80 md:w-80 md:justify-self-end lg:h-96 lg:w-96 2xl:h-[440px] 2xl:w-[440px]">
            <img
              src={imgSrc}
              alt="image-hero"
              className="xs:h-60 xs:w-60 md:h-80 md:w-80 lg:h-96 lg:w-96 2xl:h-[440px] 2xl:w-[440px]"
            />
          </div>
        ) : null}

        <div
          className={`${className} flex flex-col items-center justify-center gap-y-7 ${
            imgSrc ? 'md:items-start' : null
          }`}
        >
          <format.p
            content={contentDescription ?? ''}
            className={clsx(
              `flex flex-col items-center text-center md:block xl:text-lg`,
              imgSrc && 'py-0 text-base md:text-left lg:pr-16',
            )}
          />
          {buttonDescription ? (
            <Button asChild variant="secondary">
              <a href={socialInfo.app('sign-up', locale)}>{buttonDescription}</a>
            </Button>
          ) : null}
        </div>
      </div>
      <ArrowDown
        width={26}
        height={13}
        fill={'white'}
        alt={'arrow pointing down'}
        aria-hidden={'true'}
        className={'hidden md:flex'}
      />
    </section>
  );
};

export default HeroOther;
